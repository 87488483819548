<template>
  <v-container>
    <v-row v-if="orgs.length > 0">
      <dashboard-grid
        ref="board"
        :theme="classic"
        :dark-mode="false"
        :col-num="12"
        :row-height="30"
        :layout-editable="true"
        :datasets="dataSet"
      />
    </v-row>

    <v-row
      v-else
      class="mt-12 text-center"
      align="center"
    >
      <v-col cols="12">
        <h3>{{ $t('noOrg') }}</h3>
      </v-col>
      <v-col cols="12">
        <p>{{ $t('toAccessDashboard') }}</p>
      </v-col>
      <v-col cols="12">
        <v-btn
          to="/settings/organizations"
          color="primary"
        >
          {{ $t('startHere') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters(['accounts']),
    orgs(){
      // return this.accounts?.filter(a => a.type === "org")
      return 0
    }
  },
  props: {
    dataSet: {
      default: function ()
      {
        return [
          {
            chartInfo: {
              series: [
                {
                  name: 'Metric1',
                  data: [
                    {
                      x: 'Jan',
                      y: 5,
                    },
                    {
                      x: 'Feb',
                      y: 10,
                    },
                    {
                      x: 'Mar',
                      y: 15,
                    },
                    {
                      x: 'Apr',
                      y: 20,
                    },
                    {
                      x: 'May',
                      y: 25,
                    },
                    {
                      x: 'Jun',
                      y: 30,
                    },
                  ],
                },
                {
                  name: 'Metric2',
                  data: [
                    {
                      x: 'Jan',
                      y: 5,
                    },
                    {
                      x: 'Feb',
                      y: 10,
                    },
                    {
                      x: 'Mar',
                      y: 15,
                    },
                    {
                      x: 'Apr',
                      y: 20,
                    },
                    {
                      x: 'May',
                      y: 25,
                    },
                    {
                      x: 'Jun',
                      y: 30,
                    },
                  ],
                },
              ],
              options: {
                chart: {
                  type: 'heatmap',
                },
                title: {
                  text: 'HeatMap Chart',
                },
              },
            },
            gridInfo: {
              x: 6,
              y: 0,
              w: 6,
              h: 12,
              i: '1',
              static: false,
            },
          },
          {
            chartInfo: {
              series: [200, 12, 40, 25, 34, 6, 23],
              options: {
                chart: {
                  type: 'pie',
                },
                title: {
                  text: 'The recent inflow route',
                  align: 'center',
                  style: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                  },
                },
                labels: ['SNS', 'Recommend', 'Homepage', 'Blog', 'Kakaotalk Channel', 'Rumor', 'ETC'],
                fill: {
                  opacity: 1,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            gridInfo: {
              x: 6,
              y: 0,
              w: 6,
              h: 12,
              i: '2',
              static: false,
            },
          },
        ];
      },
    },
  },
  methods: {},
  data()
  {
    return {
      items: [],
      classic: '',
    };
  },
};
</script>

<style>
#new-chart-modal * {
  transition: none !important;
}
</style>
